@use '@sats-group/ui-lib/tokens/lightmode';
@use '@sats-group/ui-lib/tokens/spacing';

@mixin break {
  @media screen and (min-width: 600px) {
    @content;
  }
}

.table {
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    border-bottom: 1px solid lightmode.$ui-border;
    padding: spacing.$m 0 spacing.$m spacing.$m;

    &:first-child {
      padding-left: 0;
    }
  }

  th {
    text-align: left;
    vertical-align: top;
  }

  &__single {
    display: none;

    @include break {
      display: table;
    }
  }

  &__single {
    th:first-child,
    td:first-child {
      padding-left: spacing.$s;
    }

    th:last-child,
    td:last-child {
      padding-right: spacing.$s;
    }
  }

  tbody {
    background-color: lightmode.$clean;
  }

  &__multiple {
    & + & {
      margin-top: spacing.$l;
    }

    th:first-child {
      padding-left: spacing.$s;
    }

    th {
      padding-right: spacing.$s;
    }

    @include break {
      display: none;
    }
  }

  &__link {
    text-decoration: none;
  }
}
